'use strict';

angular
	.module('bringgApp')
	.factory(
		'MainNavigationVer2Service',
		function (Authentication, Application, MerchantConfigurations, $location, PRIVILEGES_TYPES) {
			var MainNavigationVer2Service = {};

			MainNavigationVer2Service.getFirstAvailableView = function () {
				var currentUser = Authentication.currentUser();
				if (_.isNull(currentUser) || _.isUndefined(currentUser)) {
					return;
				}

				var firstLocation;

				if ($location.search().next_url) {
					var nextUrl = $location.search().next_url;
					$location.search('next_url', undefined);
					return { location: nextUrl };
				}

				if (currentUser.default_welcome_url_id) {
					function findNavBarItemFromId(id) {
						let navBarItem;
						let enabled = false;

						for (const navBarItemElement of MainNavigationVer2Service.navbarItems) {
							if (navBarItemElement.id === id) {
								navBarItem = navBarItemElement;
								enabled = MainNavigationVer2Service.hasRequiredFeatures(navBarItemElement);
								break;
							}

							const possibleNavBarItem = _.find(navBarItemElement.subItems, { id });

							if (possibleNavBarItem) {
								navBarItem = possibleNavBarItem;
								enabled =
									MainNavigationVer2Service.hasRequiredFeatures(navBarItemElement) &&
									MainNavigationVer2Service.hasRequiredFeatures(possibleNavBarItem);
								break;
							}
						}

						return {
							enabled,
							navBarItem
						};
					}

					const v1ToV2MonitoringLocation = {
						top_menu_dispatch_list: 'top_menu_dispatch_v2',
						top_menu_planning: 'top_menu_planning_v2'
					};
					let defaultTab = currentUser.default_welcome_url_id;

					const { enabled, navBarItem } = findNavBarItemFromId(defaultTab);

					if (v1ToV2MonitoringLocation[navBarItem?.id] && !enabled) {
						const v2Location = findNavBarItemFromId(v1ToV2MonitoringLocation[navBarItem.id]);
						if (v2Location.enabled) {
							firstLocation = v2Location.navBarItem;
						}
					}

					if (!firstLocation) {
						firstLocation = MainNavigationVer2Service.hasRequiredFeatures(navBarItem)
							? navBarItem
							: findFirstEnabledNavBarItem(MainNavigationVer2Service.navbarItems);
					}
				}
				if (!firstLocation) {
					firstLocation = findFirstEnabledNavBarItem(MainNavigationVer2Service.navbarItems);
				}
				return firstLocation;
			};

			MainNavigationVer2Service.getFirstAvailableViewLocation = function () {
				const availableView = MainNavigationVer2Service.getFirstAvailableView();

				return availableView?.location
					? availableView.location
					: findFirstEnabledNavBarItem(availableView?.subItems)?.location;
			};

			function isMerchantHasFeatureFlag(featureFlag) {
				var currentUser = Authentication.currentUser();

				// Feature Flag has to be explicitly equals to TRUE
				return (
					_.get(MerchantConfigurations, featureFlag) === true ||
					_.get(currentUser?.feature_flags, featureFlag) === true
				);
			}

			MainNavigationVer2Service.hasRequiredFeatures = function (navItem) {
				var result = true;
				var currentUser = Authentication.currentUser();

				function isMerchantConfigurationEnabled(configuration) {
					return _.get(MerchantConfigurations, configuration) === true;
				}

				function isPDWFeatureFlag(userFeature) {
					return userFeature === 'planned_delivery_windows';
				}

				function shouldReturnPWDPrivilegeValue(userFeature) {
					return (
						isPDWFeatureFlag(userFeature) && currentUser.feature_flags.use_slots_and_blackouts_privileges
					);
				}

				function isUserHasFeatureFlag(userFeature) {
					const checkAccess = feature =>
						Array.isArray(feature)
							? feature.some(item =>
									Array.isArray(item) ? currentUser.has_access(...item) : currentUser.has_access(item)
							  )
							: currentUser.has_access(feature);

					if (typeof userFeature === 'function') {
						return checkAccess(userFeature());
					}

					if (shouldReturnPWDPrivilegeValue(userFeature)) {
						return currentUser.has_access(PRIVILEGES_TYPES.VIEW_PLANNED_DELIVERY_WINDOWS);
					}

					return checkAccess(userFeature);
				}

				if (navItem.merchantFeature) {
					if (Array.isArray(navItem.merchantFeature)) {
						result = navItem.merchantFeature.every(ff => isMerchantHasFeatureFlag(ff));
					} else {
						result = isMerchantHasFeatureFlag(navItem.merchantFeature);
					}
				}

				if (navItem.merchantConfiguration) {
					result = isMerchantConfigurationEnabled(navItem.merchantConfiguration);
				}

				if (result) {
					result = currentUser && isUserHasFeatureFlag(navItem.userFeature);
				}

				if (result && navItem.hideOnMerchantFeature) {
					result = !isMerchantHasFeatureFlag(navItem.hideOnMerchantFeature);
				}

				// case when parent has subItems but should be hidden if parent merchant feature enable
				if (result && navItem.completelyHideOnMerchantFeature) {
					result = !isMerchantHasFeatureFlag(navItem.completelyHideOnMerchantFeature);
					return result;
				}

				if (!navItem.merchantFeature && navItem.subItems && !navItem.userFeature) {
					result =
						currentUser &&
						navItem.subItems.some(function (subItem) {
							return (
								(subItem.merchantFeature ? isMerchantHasFeatureFlag(subItem.merchantFeature) : true) &&
								isUserHasFeatureFlag(subItem.userFeature)
							);
						});
				}

				// hide "main.support" when impersonating
				if (navItem.hideOnImpersonate && currentUser && currentUser.original_user_id) {
					result = false;
				}

				const intermediateResult = result;

				if (navItem.adminRequired && currentUser && !currentUser.admin) {
					result = false;
				}

				// Enable the menu item for non-admins if the user has the required priviledge
				if (
					intermediateResult &&
					navItem.adminRequired &&
					currentUser &&
					!currentUser.admin &&
					navItem.userFeature
				) {
					result = isUserHasFeatureFlag(navItem.userFeature);
				}

				if (navItem.application && currentUser) {
					result = MerchantConfigurations?.applications?.some(function (app) {
						return app.uuid === navItem.application;
					});
				}

				return result;
			};

			const getTeamsPagePrivileges = () => {
				if (isMerchantHasFeatureFlag('enable_teams_v2_privilege')) {
					return [
						[PRIVILEGES_TYPES.TEAMS_V2, PRIVILEGES_TYPES.TEAMS_V2_EDIT],
						[PRIVILEGES_TYPES.TEAMS_V2, PRIVILEGES_TYPES.TEAMS_V2_VIEW]
					];
				} else {
					return [PRIVILEGES_TYPES.TEAMS];
				}
			};

			var deliveryBlockMenuItem = {
				id: 'top_menu_delivery_blocks',
				label: 'MAIN.DELIVERY_BLOCKS',
				icon: 'bringg-icon-delivery-slots',
				location: 'delivery-blocks-v2',
				merchantFeature: 'enable_delivery_blocks'
			};

			var plannedDeliveryWindows = {
				id: 'top_menu_planned_delivery_windows',
				location: 'planned-delivery-windows',
				userFeature: 'planned_delivery_windows',
				merchantFeature: 'planned_delivery_windows',
				label: 'MAIN.PLANNED_DELIVERY_WINDOWS',
				icon: 'bringg-icon-delivery-slots'
			};

			var serviceAreasMenuItem = {
				id: 'top_menu_vehicles',
				label: 'MAIN.SERVICE_AREA',
				icon: 'bringg-icon-radar',
				location: 'service-areas',
				merchantFeature: 'dispatcher_new_side_menu_items'
			};

			var resourcesMenuItem = {
				id: 'top_menu_resources',
				label: 'MAIN.RESOURCES',
				icon: 'bringg-icon-wheel',
				subItems: [
					{
						...deliveryBlockMenuItem,
						completelyHideOnMerchantFeature: 'dispatcher_new_side_menu_items'
					},
					{
						id: 'top_menu_teams',
						label: 'MAIN.TEAMS',
						icon: 'bringg-icon-team',
						location: 'drivers/teams',
						userFeature: getTeamsPagePrivileges,
						merchantFeature: 'enable_teams'
					},
					{
						id: 'top_menu_drivers',
						label: 'MAIN.DRIVERS',
						icon: 'bringg-icon-drivers',
						location: 'drivers',
						userFeature: PRIVILEGES_TYPES.DRIVERS
					},
					{
						id: 'top_menu_vehicles',
						label: 'MAIN.VEHICLES',
						icon: 'bringg-icon-truck',
						location: 'vehicles-manager',
						merchantFeature: 'new_vehicles_page'
					},
					{
						id: 'top_menu_dispatchers',
						label: 'MAIN.DISPATCHERS',
						icon: 'bringg-icon-user',
						location: 'dispatchers',
						merchantFeature: 'dispatcher_new_side_menu_items',
						userFeature: [
							[PRIVILEGES_TYPES.DISPATCHER_PROFILE, PRIVILEGES_TYPES.DISPATCHER_PROFILE_EDIT],
							[PRIVILEGES_TYPES.DISPATCHER_PROFILE, PRIVILEGES_TYPES.DISPATCHER_PROFILE_VIEW]
						]
					}
				]
			};

			var planningToolsMenuItem = {
				id: 'planning_tools',
				label: 'MAIN.PLANNING_TOOLS',
				icon: 'bringg-icon-calendar',
				merchantFeature: 'dispatcher_new_side_menu_items',
				subItems: [serviceAreasMenuItem, deliveryBlockMenuItem, plannedDeliveryWindows]
			};

			MainNavigationVer2Service.navbarItems = [
				{
					id: 'top_menu_dispatch',
					menuLocation: 'manage',
					userFeature: 'dispatch',
					location: 'present',
					label: 'MAIN.DISPATCH',
					icon: 'bringg-icon-man',
					subItems: [
						{
							id: 'top_menu_dispatch_list',
							menuLocation: 'list',
							userFeature: 'dispatch',
							location: 'list',
							label: 'MAIN.LIST',
							icon: 'bringg-icon-list'
						},
						{
							id: 'top_menu_map',
							menuLocation: 'map',
							userFeature: 'dispatch',
							location: 'map',
							label: 'MAIN.MAP',
							icon: 'bringg-icon-map'
						},
						{
							id: 'top_menu_map',
							menuLocation: 'runs',
							userFeature: 'dispatch',
							location: 'runs',
							label: 'RUN.RUNS',
							icon: 'bringg-icon-route',
							hideOnMerchantFeature: 'enable_routes_monitoring_screen'
						},
						{
							id: 'top_menu_runs_monitoring_screen',
							menuLocation: 'runs-monitoring-screen',
							userFeature: 'dispatch',
							merchantFeature: 'enable_routes_monitoring_screen',
							location: 'runs-monitoring',
							label: 'RUN.RUNS',
							icon: 'bringg-icon-route'
						}
					],
					hideOnMerchantFeature: 'enable_order_explorer'
				},
				{
					id: 'top_menu_dispatch_v2',
					menuLocation: 'manage',
					userFeature: 'dispatch',
					merchantFeature: 'enable_order_explorer',
					location: 'dispatch/runs-monitoring',
					childLocations: ['dispatch/order-details'],
					label: 'MAIN.DISPATCH',
					icon: 'bringg-icon-man'
				},
				{
					id: 'top_menu_planning',
					location: 'planning',
					userFeature: 'dispatch',
					merchantFeature: 'planning_phase_exists',
					label: 'MAIN.PLANNING',
					icon: 'bringg-icon-calendar',
					hideOnMerchantFeature: 'enable_order_explorer'
				},
				{
					id: 'top_menu_planning_v2',
					location: 'planning/order-details',
					childLocations: ['planning/runs-monitoring'],
					userFeature: 'dispatch',
					merchantFeature: ['enable_order_explorer', 'planning_phase_exists'],
					label: 'MAIN.PLANNING',
					icon: 'bringg-icon-calendar'
				},
				{
					id: 'top_menu_floating_inventories_monitoring_screen',
					menuLocation: 'floating-inventories-monitoring-screen',
					userFeature: 'dispatch',
					application: Application.APPLICATIONS.FloatingInventoryApp,
					location: 'floating-inventories-monitor',
					label: 'MAIN.FLOATING_INVENTORY',
					icon: 'bringg-icon-inventory'
				},
				{
					id: 'top_menu_chat',
					location: 'chat',
					userFeature: 'enable_chat',
					merchantFeature: 'enable_chat',
					label: 'MAIN.CHAT',
					icon: 'bringg-icon-chat-bubble'
				},
				{
					id: 'top_menu_sendbird_chat',
					location: 'chat_v2', // SENDBIRD_CHAT_ROUTE_PATH
					merchantConfiguration: 'enable_chat_v2',
					label: 'MAIN.CHAT',
					icon: 'bringg-icon-chat-bubble'
				},
				{
					id: 'top_menu_history',
					location: 'history',
					userFeature: 'history',
					label: 'MAIN.HISTORY',
					icon: 'bringg-icon-reschedule'
				},
				{
					id: 'top_menu_delivery_hub',
					label: 'MAIN.DELIVERY_HUB',
					icon: 'bringg-icon-connected-hub',
					merchantConfiguration: 'delivery_hub_main_menu_ui',
					adminRequired: true,
					userFeature: PRIVILEGES_TYPES.ENABLE_SETTING_PAGE,
					subItems: [
						{
							id: 'top_menu_fleet_router',
							label: 'MAIN.FLEET_ROUTER',
							icon: 'bringg-icon-fork-left-right-back',
							location: 'fleets-rule-list',
							merchantConfiguration: 'delivery_hub_main_menu_ui'
						},
						{
							id: 'top_menu_my_dps',
							label: 'MAIN.MY_DELIVERY_PROVIDERS',
							icon: 'bringg-icon-truck',
							location: 'delivery-catalog-connect',
							merchantConfiguration: 'delivery_hub_main_menu_ui'
						},
						{
							id: 'top_menu_dp_catalog',
							label: 'MAIN.DP_CATALOG',
							icon: 'bringg-icon-library-books',
							location: 'delivery-catalog',
							merchantConfiguration: 'delivery_hub_main_menu_ui'
						},
						{
							id: 'top_menu_billing_portal',
							label: 'MAIN.BILLING_PORTAL',
							icon: 'bringg-icon-sheet-with-dollar',
							userFeature: PRIVILEGES_TYPES.BILLING_PORTAL,
							location: 'billing',
							merchantFeature: 'billing_portal'
						}
					]
				},
				resourcesMenuItem,
				planningToolsMenuItem,
				{
					id: 'top_menu_customers',
					location: 'customers',
					userFeature: 'customers_menu',
					label: 'MAIN.CUSTOMERS',
					icon: 'bringg-icon-users'
				},
				{
					...plannedDeliveryWindows,
					completelyHideOnMerchantFeature: 'dispatcher_new_side_menu_items'
				},
				{
					id: 'top_menu_analytics',
					menuLocation: 'manage',
					location: 'present',
					label: 'MAIN.ANALYTICS',
					icon: 'bringg-icon-graph',
					subItems: [
						{
							id: 'top_menu_bi_dashboard',
							location: 'analytics/dashboards',
							label: 'MAIN.BI_DASHBOARD',
							icon: 'bringg-icon-analytics'
						},
						{
							id: 'top_menu_reports',
							location: 'analytics/reports',
							userFeature: 'reports',
							label: 'MAIN.REPORTS',
							icon: 'bringg-icon-file-alt'
						}
					]
				},
				{
					id: 'top_menu_support',
					location: 'support',
					userFeature: 'customer_care',
					hideOnImpersonate: true,
					merchantFeature: 'enable_support',
					label: 'MAIN.SUPPORT',
					icon: 'bringg-icon-support'
				},
				{
					id: 'top_menu_kds',
					location: 'kds',
					merchantFeature: 'enable_kds',
					hideOnImpersonate: false,
					label: 'MAIN.KDS',
					icon: 'bringg-icon-preparation-restaurant'
				}
			];

			var findFirstEnabledNavBarItem = function (navBarItems) {
				return _.first(_.filter(navBarItems, MainNavigationVer2Service.hasRequiredFeatures));
			};

			return MainNavigationVer2Service;
		}
	);
